import React, { useEffect, useState } from "react";
import './styles/App.css';
import './styles/mint.css';
import myNft from './utils/MyNft.json';
import { ethers, providers } from "ethers";
import { Web3Provider } from "@ethersproject/providers";
import Modal from 'react-modal';

import {
    Web3ReactProvider,
    useWeb3React,
    UnsupportedChainIdError
} from "@web3-react/core";
import {
    NoEthereumProviderError,
    UserRejectedRequestError as UserRejectedRequestErrorInjected
} from "@web3-react/injected-connector";
import {
    URI_AVAILABLE,
    UserRejectedRequestError as UserRejectedRequestErrorWalletConnect
} from "@web3-react/walletconnect-connector";
import { useEagerConnect, useInactiveListener } from "./hooks";

import {
    injected,
    walletconnect,
    walletlink,
} from "./Connector/Connectors";
import { Spinner } from "./Spinner";

// CONFIGS
    /* 
        WEB SITE WORDING:
        Presale 1: First Pre-sale 
        Presale 2: Final Pre-sale

        CONFIGS_MINT_PRICE_CALC:
        TESTING:      0.01 (ETH)
        PRESALE 1:    0.04
        PRESALE 2:    0.045
        PUBLIC SALE:  0.05
    */
let CONFIGS_MINT_PRICE_CALC = 0.00; // used for calculating
const CONFIGS_TOTALSUPPLY_CALC = 9663; // used for calculating -- I think we may need this to calculate SOLD OUT
let CONFIGS_TOTALSUPPLY_DISPLAY = '9,663'; // used for display purposes only...
let CONFIGS_NFTS_RESERVED = 200; // I think we may need this to calculate SOLD OUT
let CONFIGS_WALLET_LIMIT = 25; // testing only! Actual settings will be 3 presale1/presale2, then 10 for public sale.
const CONFIGS_CONTRACT_ADDRESS = "0xdaA3D1A810322003248e22ED739762E3E0bCe7a9";
let CONFIGS_CHAIN_ID = 1; //set the configs chain id -- 1==MAINNET, 4==RINKEBY
let CONFIGS_CHAIN_DESCRIPTION = 'Mainnet'; // used in the ALERT
let CONFIGS_NETWORK = ''; // make either blank or 'rinkeby.' (with the period) -- used for link building only
const CONFIGS_JSON_METADATA_BASEURI = 'https://api.generativenfts.io/loopies/';
let CONFIGS_OPENSEA_URL = 'opensea.io'; // make either 'opensea.io' or 'testnets.opensea.io'
let CONFIGS_NFT_TITLE_SINGULAR = 'LOOPIE';
let CONFIGS_NFT_TITLE_PLURAL = 'LOOPIES';
let CONFIGS_MERKLE_API = "https://standard-template-allowlist-api.netlify.app/.netlify/functions/express/api/"; //deployed merkle api
let CONFIGS_URLS_DISCORD = '';
let CONFIGS_URLS_INSTAGRAM = '';
let CONFIGS_URLS_TWITTER = 'https://twitter.com/LoopieLooNFT';
let CONFIGS_URLS_FACEBOOK = '';
let CONFIGS_URLS_YOUTUBE = '';
let CONFIGS_URLS_ROADMAP = '';
let CONFIGS_URLS_OPENSEA_COLLECTION = 'https://'+ CONFIGS_OPENSEA_URL +'/collection/loopieloo';
let CONFIGS_URLS_PROJECTWEBSITE = 'https://loopies.generativenfts.io/';
let CONFIGS_DEV_URL = 'https://www.linkedin.com/in/jimdee/';
let CONFIGS_CONTRACT_URL = 'https://' + CONFIGS_NETWORK + 'etherscan.io/address/' + CONFIGS_CONTRACT_ADDRESS;
let CONFIGS_SHOW_COLLECTION = 0; // make 1 to show, 0 to NOT show NFT collection at bottom.

// fill this object with some terms that would be found from our solidity contract's errors,
// and then we can write custom responses for the alerts (but keep them all up here):
let SOLIDITY_ERROR_LIST = {
    1: {
        'error': '[error text snippet from wallet error]',
        'response': '[alert response]'
    },
    2: {
        'error': 'exceed the wallet limit',
        'response': 'Your transaction would exceed the wallet limit.'
    },
    3: {
        'error': 'exceed the max supply',
        'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
    },
    4: {
        'error': 'exceed max supply',
        'response': 'This drop is sold out and/or you are trying to purchase more than the remaining supply. Check OpenSea for the secondary market.'
    },
    5: {
        'error': 'Sale is not active',
        'response': 'The sale has been disabled on the smart contract.'
    },
    6: {
        'error': 'Not enough ether sent',
        'response': 'You sent too little ETH for your purchase. If you feel this error is wrong, drop the team a note.'
    },
    7: {
        'error': 'User denied transaction',
        'response': 'The user has denied the current transaction.'
    },
    8: {
        'error': 'already claimed',
        'response': 'It looks like you have already claimed your allowlist NFTs.'
    },
    9: {
        'error': 'insufficient funds',
        'response': 'Insufficient funds. Please add enough ETH to your wallet for this NFT purchase + gas.'
    },
    10: {
        'error': 'are not allowlisted',
        'response': 'Sorry, you are not on the presale list. Please contact the team if you believe this to be an error.'
    }
}

let GENERIC_RESPONSE = 'Transaction canceled. Usually if you see this, it means that you have rejected a transaction. If you feel that this message displayed because of another error, please alert the devs and we will have a look.';


const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};


const connectorsByName = {
    Metamask: injected,
    WalletConnect: walletconnect,
    Coinbase: walletlink,
};

const connectorImagesByName = {
    Metamask: './MetaMask_Fox.svg.png',
    WalletConnect: './walletconnect.png',
    Coinbase: './coinbase-logo-freelogovectors.net_.png',
};

function getErrorMessage(error) {
    if (error instanceof NoEthereumProviderError) {
        return "No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.";
    } else if (error instanceof UnsupportedChainIdError) {
        return "You're connected to an unsupported network.";
    } else if (
        error instanceof UserRejectedRequestErrorInjected ||
        error instanceof UserRejectedRequestErrorWalletConnect
    ) {
        return "Please authorize this website to access your Ethereum account.";
    } else {
        console.error(error);
        return "An unknown error occurred. Check the console for more details.";
    }
}

function getLibrary(provider, connector) {
    const library = new Web3Provider(provider);
    library.pollingInterval = 8000;
    return library;
}

export default function() {
    return ( <
        Web3ReactProvider getLibrary = { getLibrary } >
        <
        App / >
        <
        /Web3ReactProvider>
    )
};


const App = () => {

        let subtitle;
        const [modalIsOpen, setIsOpen] = React.useState(false);

        function openModal() {
            setIsOpen(true);
        }

        function afterOpenModal() {
            // references are now sync'd and can be accessed.
            subtitle.style.color = '#f00';
        }

        function closeModal() {
            setIsOpen(false);
        }

        const context = useWeb3React();
        const {
            connector,
            library,
            chainId,
            account,
            activate,
            deactivate,
            active,
            error
        } = context;

        // handle logic to recognize the connector currently being activated
        const [activatingConnector, setActivatingConnector] = useState();

        useEffect(() => {
            console.log('running')
            if (activatingConnector && activatingConnector === connector) {
                setActivatingConnector(undefined);
            }
        }, [activatingConnector, connector]);

        // handle logic to eagerly connect to the injected ethereum provider, if it exists and has granted access already
        const triedEager = useEagerConnect();

        // handle logic to connect in reaction to certain events on the injected ethereum provider, if it exists
        useInactiveListener(!triedEager || !!activatingConnector);

        const [mintCount, setMintCount] = useState(0);
        const [value, setValue] = useState(1);
        const [myNFTs, setMyNFTs] = useState([]);
        const [trackMintCount, setTrackMintCount] = useState(0);
        const [trackAnyMintCount, setTrackAnyMintCount] = useState(0);
        const [isSold, setIsSold] = useState(false);
        const [isLibrary, setIsLibrary] = useState(false);

        let tokenId;
        let signer;
        let currentTotalSupply;

        const handleChange = (event) => {
            setValue(event.target.value);
            console.log("Mint amount = " + value);
        };

        /*
        const totalSupplyGetter = async() => {
             try {
                 const provider = library;
                 const signer = provider.getSigner();
                 const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);
                 let currentTotalSupply = await connectedContract.totalSupply();
                 console.log('currentTotalSupply: ' + currentTotalSupply);
             } catch (error) {
                 console.log(error);
             }
         }
         */

        const setupLibrary = async() => {

            try {


                if (library) {
                    setIsLibrary(true);
                }

                console.log("Setup Library");

            } catch (error) {
                console.log(error);
            }
        }

        const setupEventListener = async() => {

            try {
                const wallet = library;
                signer = wallet.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                // event listener
                connectedContract.on("Mint", (from, tokenId) => {
                    setTrackAnyMintCount((trackAnyMintCount) => trackAnyMintCount + 1);
                    console.log(from, tokenId.toNumber());
                    //setMintCount(tokenId.toNumber());

                    //totalSupplyGetter();
                });

                if (mintCount >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                    setMintCount(10000);
                    setIsSold(true);
                }
                // if (mintCount >= 5) {
                //     setMintCount(10000);
                //     setIsSold(true);
                // }
                console.log('mintCount: ' + mintCount);

                if (library) {
                    setIsLibrary(true);
                }

                console.log("Setup event listener!");

            } catch (error) {
                console.log(error);
            }
        }

        const askContractToMintNft = async() => {
            console.log(chainId);
            if (chainId !== CONFIGS_CHAIN_ID) {
                alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
                return;
            }

            try {
                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                console.log("Public Minting: Popping wallet now to pay gas.")
                console.log(value);

                let nftTxn = await connectedContract.mint(value, {    
                    value: ethers.utils.parseUnits((CONFIGS_MINT_PRICE_CALC * value).toString())
                });

                console.log("Public Minting: Please wait...")
                await nftTxn.wait();

                alert("You have successfully minted! Your NFT(s) should appear in your wallet and on OpenSea shortly.");

                //console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
                setTrackMintCount(trackMintCount + 1);

            } catch (e) {
                console.log(e)
                var error = e.toString().split(',');
                var rawErrorMessage = e.toString();
                let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
                let errorFound = 0;

                // some canned responses from above:
                for (let i = 1; i <= numSolidityErrors; i++) {
                    var targetString = SOLIDITY_ERROR_LIST[i].error;
                    if (rawErrorMessage.search(targetString) > 1) {
                        let theMessage = SOLIDITY_ERROR_LIST[i].response;
                        errorFound++;
                        alert(theMessage);
                        console.log(theMessage);
                    }
                }

                // or if no error was found yet:
                if (!errorFound) {

                    alert(GENERIC_RESPONSE);
                    console.log(rawErrorMessage);

                }

            }

        }


        const allowlistMint = async() => {
            console.log(chainId);
            if (chainId !== CONFIGS_CHAIN_ID) {
                alert("Please connect to " + CONFIGS_CHAIN_DESCRIPTION);
                return;
            }

            try {
                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                const walletAdd = await signer.getAddress();
                console.log(String(walletAdd));
                const requestOptions = {
                    "referrerPolicy": "strict-origin-when-cross-origin",
                    "body": null,
                    "method": "GET",
                    "mode": "cors",
                    "credentials": "omit"
                }

                let res = await fetch(CONFIGS_MERKLE_API + String(walletAdd), requestOptions);
                console.log(res);

                let merkleObject = await res.json();
                console.log(merkleObject);

                if (!merkleObject.valid) {
                    alert("Sorry, this is an allowlist-only minting period, and your wallet is not on the list. Please return for the public mint, and/or chat with a team member if you believe this to be in error.");
                    return;
                }

                console.log("Allowlist Mint: Popping wallet now to pay gas.");
                console.log(value);

                let nftTxn = await connectedContract.allowlistMint(merkleObject.proof, value, {
                    value: ethers.utils.parseUnits((CONFIGS_MINT_PRICE_CALC * value).toString())
                });

                console.log("Allowlist Mint: Please wait...")
                await nftTxn.wait();
                alert("You have succesfully minted! Your NFT(s) should appear in your wallet and on OpenSea shortly.");
                console.log("Minted, see transaction: https://" + CONFIGS_NETWORK + "etherscan.io/tx/" + nftTxn.hash);
                setTrackMintCount(trackMintCount + 1);

            } catch (e) {

                console.log(e)
                var error = e.toString().split(',');
                var rawErrorMessage = e.toString();
                let numSolidityErrors = Object.keys(SOLIDITY_ERROR_LIST).length;
                let errorFound = 0;

                // some canned responses from above:
                for (let i = 1; i <= numSolidityErrors; i++) {
                    var targetString = SOLIDITY_ERROR_LIST[i].error;
                    if (rawErrorMessage.search(targetString) > 1) {
                        let theMessage = SOLIDITY_ERROR_LIST[i].response;
                        errorFound++;
                        alert(theMessage);
                        console.log(theMessage);
                    }
                }

                // or if no error was found yet:
                if (!errorFound) {

                    alert(GENERIC_RESPONSE);
                    console.log(rawErrorMessage);

                }

            }

        }




        const getTotalNFTsMintedSoFar = async() => {

            try {

                const provider = library;
                const signer = provider.getSigner();
                const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                console.log("Getting Mint Count")
                let mint_count = await connectedContract.totalSupply();

                if (mint_count >= (CONFIGS_TOTALSUPPLY_CALC - CONFIGS_NFTS_RESERVED)) {
                    setIsSold(true);
                }

                console.log(ethers.utils.formatUnits(mint_count, 0));
                setMintCount(ethers.utils.formatUnits(mint_count, 0));

                console.log(`Set mint count is ${mint_count}`);

                console.log('Getting Minted NFTs');
                const userBalance = await connectedContract.balanceOf(account);
                console.log(userBalance);

            } catch (error) {

                console.log(error)

            }
        }

        const getTotalOwnerNFTs = async() => {

            if (CONFIGS_SHOW_COLLECTION == 1) {

                try {
                    console.log("Getting total owner nfts");
                    const provider = library;
                    const signer = provider.getSigner();
                    const connectedContract = new ethers.Contract(CONFIGS_CONTRACT_ADDRESS, myNft.abi, signer);

                    const userBalance = await connectedContract.balanceOf(account);
                    console.log(userBalance);
                    let arrNFT = []
                    let data = null;

                    for (let i = 0; i < userBalance; i++) {
                        console.log("entering collection for time: ", i);
                        tokenId = await connectedContract.tokenOfOwnerByIndex(account, i);
                        var openSeaLink = "https://" + CONFIGS_OPENSEA_URL + "/assets/" + CONFIGS_CONTRACT_ADDRESS + "/" + tokenId;

                        let metadataLink = CONFIGS_JSON_METADATA_BASEURI + tokenId;

                        await fetch(metadataLink)
                            .then(
                                function(response) {

                                    if (response.status !== 200) {
                                        console.log('Error code: ' + response.status);
                                        return;
                                    }

                                    response.json().then(function(data) {

                                        //console.log(data);

                                        //console.log('CurrentID: ' + tokenId);
                                        var jsonRes = JSON.parse(JSON.stringify(data));
                                        var imageSrc = jsonRes.image;
                                        imageSrc = imageSrc.replace("ipfs://", "https://ipfs.io/ipfs/");
                                        data.image = imageSrc;
                                        data.openSeaLink = openSeaLink;
                                        //console.log('img src=' + imageSrc);
                                        arrNFT.push(data);
                                        setMyNFTs([...arrNFT]);
                                        let collectionImage = '<div class="collectionItem"><a target="blank" href="' + openSeaLink + '"><img class="collectionImage" src="' + imageSrc + '" /><div class="collectionTokenTitle">' + CONFIGS_NFT_TITLE_SINGULAR + ' #' + tokenId + '</div></a></div>';

                                    });
                                }
                            )
                            .catch(function(err) {
                                console.log('Fetch Error :-S', err);
                            });

                    }

                    console.log(myNFTs);
                    console.log(arrNFT);

                } catch (error) {
                    console.log(error)
                }

            }

        }

        const onDisconnect = async() => {
            console.log("Killing the wallet connection", library);
            setIsLibrary(false);
            // disconnect wallet
            deactivate();
            console.log(account);
            console.log(library);
            console.log(connector);
        }

        useEffect(() => {
            setupLibrary();
        });

        useEffect(() => {
            if (library) { setupEventListener() };
        }, [isLibrary]);

        useEffect(() => {
            console.log(isLibrary);
            console.log(trackAnyMintCount);
            if (library) { getTotalNFTsMintedSoFar() }
        }, [isLibrary, trackAnyMintCount]);

        useEffect(() => {
            if (library) { getTotalOwnerNFTs() }
        }, [isLibrary, trackMintCount]);

        // useEffect(() => {
        //     if (library) { totalSupplyGetter() };
        // });

        /*
         * Added a conditional render! We don't want to show Connect to Wallet if we're already conencted :).
         */

        return (

                <div className = "App">

                { /* TOP AND FOOTER MENUS   <img src="icon-ds.gif" alt="" />  */ } 
                <div class = "header" >
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }>TWITTER</a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }>OPENSEA</a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }>VERIFIED ERC721A CONTRACT</a> 
                    <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_DEV_URL }>SAFE / FULLY DOXXED DEV</a> 

                </div>
                <div className="topmessage">            <p><b>LoopieLooNFTs</b> are 9,663 (phone code for WOOF) dog portraits living their best lives out on the fringes of the Ethereum blockchain. These feral NFTs can't be controlled with any so-called "roadmap," restrained with any "utility," or confined within some boring web2 "website." A <a href="https://twitter.com/LoopieLooNFT">Twitter account</a> occasionally reporting their whereabouts is about all they will abide. LoopieLooNFTs are basically a giant pack of badass cattle dogs roamin' the chain, herdin' all the straggler 1/1 varmints they find along the way, just for sport. Each trippy doggo's digital frame adds a touch of class to these wild beasts; they'll stand out as fine, presentable, attractive modern-art additions to degen ETH wallets worldwide. Noble as the pups are, each one of course features not one, not two, but <b>*three* Shakespearean quotes</b> related to dogs. And defiantly setting them apart from anything else in the canine metaverse, each LoopieLooNFT bears a <b>unique name</b> generated from more than 1,000 possible words. As for rarity, surely some underutilized bot will swing by and sort out the pack hierarchy, as there are definitely some alpha dogs here. And hey, if you mint or acquire one, please howl at the ol "wen moon" by tweeting out your doggo's name, tagging <a href="https://twitter.com/LoopieLooNFT">@LoopieLooNFT</a>.</p></div>

                <div className= "clear" > </div>

                <div className= "mintBoxWrapper">

                <div className= "mintBoxLeft"> </div>

                <div className= "mintBoxRight" >

                <img className= "mobileOnly" style = { { width: "50%" } } src="promo3.gif" alt="" />

                <div id = "DAppArea" >

                <p className="welcome">SUPPLY: <span className="yellow"> { CONFIGS_TOTALSUPPLY_DISPLAY }{' '}NFTs</span>{'   '}|{'    '} <span className="yellow">FREE</span> TO MINT!</p>

                <div className = "clear"></div> 

                { /* PUBLIC SALE HEADER -- UPDATE FOR PRESALE / PUBLC SALE!!!!!!! */ }
                <h2 style = { { marginBottom: 0 } }> <span className="pageHighlightColor">LOOPIE LOO NFT</span><br /><span className="white">FREE MINT IS SOLD OUT!</span></h2>

                {/*<p className="ercmsg">ERC721A contract means you can mint multiples for almost a single gas cost!</p>*/}

                    {
                        (active === false) ? ( 

                            <div id="prepare">

                                <p><br/><b> Note: </b> If you do not have a wallet installed, you will not see a "Connect Wallet" button.<br/></p>

                                <button onClick = { openModal } className = "btn-primary pageButton" id = "connectButton" type="button" >&#9818;&nbsp;Connect Wallet&nbsp;&#9818;</button> 

                                <Modal isOpen = { modalIsOpen } onAfterOpen = { afterOpenModal } onRequestClose = { closeModal } style = { customStyles } contentLabel = "Wallet Connection"> 

                                <h2 ref = { (_subtitle) => (subtitle = _subtitle) }>Choose Wallet Provider</h2>
                                <button onClick = { closeModal }>close</button>  
                           
                            <div> 
                            </div>
                            {
                                Object.keys(connectorsByName).map(name => {
                                        const currentConnector = connectorsByName[name];
                                        const activating = currentConnector === activatingConnector;
                                        const connected = currentConnector === connector;

                                        return (

                                            <button className = "providerChoices" key = { name } onClick = { () => {
                                                    setActivatingConnector(currentConnector);
                                                    activate(connectorsByName[name]);
                                                    closeModal();
                                                }
                                            }>

                                                <div style = { { position: "absolute", top: "0", left: "0", height: "100%", display: "flex", alignItems: "center", color: "black", margin: "0 0 0 1rem" } }>
                                                { activating && ( < Spinner color = { "black" } style = { { height: "25%", marginLeft: "-1rem" } } /> ) }</div> 

                                                <img className = 'logo-wallet' src = { connectorImagesByName[name] } alt="" /> 
                                                <span className = "walletName" > { name } </span>
                                            </button>
                                        );

                                    })
                                }

                                </Modal>

                                </div>

                            ): (

                                (isSold === false) ? ( 

                                    <div>

                                        <p className = "sub-text" > Minted:{ ' ' }<span id="currentMintCount">{ mintCount }</span> { ' ' } / { ' ' } { CONFIGS_TOTALSUPPLY_DISPLAY }</p>

                                        {/* 
                                        <p style = { { marginBottom: 0 } } > Select Quantity { ' ' }(Limit { CONFIGS_WALLET_LIMIT } { ' ' }/ wallet):</p>
                                        */}

                                        <p style = { { marginBottom: 0 } }>Select Quantity (max { CONFIGS_WALLET_LIMIT }):</p>

                                        <div className = "theSlider">
                                            <input id = "sliderVal"  className = "slider"  onChange = { handleChange }  max = { CONFIGS_WALLET_LIMIT } min = "1" type = "range"  value = { value } /> 
                                        </div>                                        

                                        { /* PRESALE MINT BUTTON    
                                        <button onClick = { allowlistMint } className = "btn-custom btn-primary pageButton" type = "button" id = "theMintButton" > Mint { value } { (value < 2) ? CONFIGS_NFT_TITLE_SINGULAR: CONFIGS_NFT_TITLE_PLURAL
                                        } </button>
                                        */ }
                                        

                                        { /* PUBLIC SALE MINT BUTTON -- DON'T FORGET TO ACTIVATE PUBLIC SALE IN THE CONTRACT!!!!!!!!! */ }
                                        <button onClick={ askContractToMintNft } className="btn-custom btn-primary pageButton" type="button" id="theMintButton">Mint { value } { ( value < 2 ) ? CONFIGS_NFT_TITLE_SINGULAR : CONFIGS_NFT_TITLE_PLURAL }, <span className="freeprice">0 ETH + gas</span></button> 
                                        
                                        { /*
                                        <div id = "totalPrice" >@{ (CONFIGS_MINT_PRICE_CALC * value).toFixed(3) } ETH + gas </div>
                                        */}

                                        <div id = "connected">

                                            <button onClick = { onDisconnect } className="btn btn-primary pageButton" id="disconnectButton" type="button">Disconnect <span class='qty smaller fixcaps'> 0x{ String(account).slice(2, 5) + "..." + String(account).slice(-4) }</span></button>

                                            <p className="buttonMessage">After minting, head to <a target="_blank" href={ 'http://'+ CONFIGS_OPENSEA_URL + '/' + account }>your wallet on OpenSea</a> to view.</p>

                                        </div> 

                                    </div>

                                ) : (

                                    <div id="totalPrice"><a href={CONFIGS_URLS_OPENSEA_COLLECTION}><span className="yellow">Have a look on OpenSea</span></a> to grab a rare on the secondary market.<br /><br />Frens, please follow Jim (<a href="https://twitter.com/SwiggaJuice"><span className="yellow">@SwiggaJuice</span></a>) on Twitter for alerts on any future freemints and/or other degen matters. Kthx.</div>

                                )

                            )
                        }

                        <p><span className = "soldFont" id = "amountSold"> </span></p>

                        </div>

                        </div>

                        </div>

                        <div className = "clear" > </div>

                        {

                            (CONFIGS_SHOW_COLLECTION == 1) ? (

                                <div>

                                <div className = "collectionWrapper" >

                                <h2>Your NFTs From This Collection</h2>

                                <p style = { { maxWidth: "80%", marginLeft: "auto", marginRight: "auto", fontSize: "125%", padding: "20px" } } > After purchasing NFTs from this collection, your NFTs will be written to the blockchain and will then appear below.They will also link to OpenSea, where you will be able to view NFT properties(once revealed), view the collection, and buy / sell / trade NFTs. </p>

                                <div className = "collectionRow" id = "renderCollection">

                                {
                                    myNFTs.map((myNFT) =>

                                        <div key = { myNFT.name } className = "collectionItem">
                                            <a target = "_blank" rel = "noreferrer" href = { myNFT.openSeaLink }>
                                                <img className = "collectionImage"  src = { myNFT.image }  alt = "" />
                                                <div className = "collectionTokenTitle">
                                                    <p>{ myNFT.name }</p>
                                                </div>
                                            </a>
                                        </div>

                                    )
                                }

                                </div>

                                </div>

                                <div className = "clear" ></div> 
                                

                                </div>

                            ) : ( < div className = "noCollectionSpacer" > </div> )

                            }

                            <div className="bottommessage"><p><b>ABOUT THIS DROP:</b> This is a 100% free mint, offered to the NFT community as a solo project by Jim Dee of <a target="_blank" href="https://generativenfts.io/">GenerativeNFTs.io</a> -- a well-known, deeply experienced web3 dev company that has spearheaded more than 15 drops on the Ethereum mainnet, including a handful of sellouts. Many of you probably recognize me from my <a target="_blank" href="https://medium.com/web-design-web-developer-magazine">generative art programming articles on Medium</a>. And, in the spirit of full doxxing, you can find me on LinkedIn, <a target="_blank" href="https://www.linkedin.com/in/jimdee/">here</a>. I would also encourage you to inspect the contract code, if you like, which lives on Etherscan, <a target="_blank" href="https://etherscan.io/address/0xdaA3D1A810322003248e22ED739762E3E0bCe7a9#code">here</a>. I'm including this paragraph as a model for the kind of transparency that I believe all NFT drops should demonstrate, regardless of whether they're freemints or paid. It's important for minters to understand who they're interacting with and what the code says/does. Thankfully, this isn't one of those mints with thousands of degens racing here to grab NFTs before anyone else, but you should know that those circumstances lead to hasty decisions by minters. This mint is 100% safe, but you should always be super careful with mints. For example, if you're chasing down brief, quick-opportunity buys or freemints, and you don't have time to be super careful about it, get into the habit of using burner wallets. That all said, THANK YOU for adopting some cattle dogs! I hope they prove faithful companions and watchdogs in your wallets for years to come.</p></div>

                            <div className = "footer">
                            <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_TWITTER }>TWITTER</a> 
                            <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_URLS_OPENSEA_COLLECTION }>OPENSEA</a> 
                            <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_CONTRACT_URL }>VERIFIED ERC721A CONTRACT</a> 
                            <a className="pageButton" target="_blank" rel="" href="https://raritysniper.com/nft-drops-calendar">RARITY SNIPER</a> 
                            <a className="pageButton" target="_blank" rel="noreferrer" href={ CONFIGS_DEV_URL }>SAFE / FULLY DOXXED DEV</a> 

                            </div>

                            </div>
                        );
                    };
